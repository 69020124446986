<script setup lang="ts">
const { url } = useImageStorage()

defineEmits([
  'click:menu-open',
])
</script>

<template>
  <div class="h-11 flex items-center gap-2">
    <AButton
      button-variant="ghost"
      use-leading
      icon-lead-name="i-ri-menu-2-fill"
      @click="() => $emit('click:menu-open')"
    />
    <NuxtImg
      class="w-10 h-10 cursor-pointer"
      :src="url(true, '/assets/wnm_logo.svg')"
      @click="navigateTo('/')"
    />
    <div class="flex-auto" />
    <AButton
      button-variant="ghost"
      use-leading
      icon-lead-name="i-ri-chat-smile-3-line"
      @click="navigateTo('/my/chat')"
    />
    <AButton
      button-variant="ghost"
      use-leading
      icon-lead-name="i-ri-search-2-line"
    />
  </div>
</template>
