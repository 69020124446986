<script setup lang="ts">
const toast = useToast()
const { t } = useI18n()

const { logout } = useFetchComposable()

const { userData, userCoreId } = storeToRefs(useUserDataStore())

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  required: true,
})

const clickLogout = () => {
  logout()
  userData.value = null
  userCoreId.value = ''
  menuOpenTrigger.value = false

  toast.add({ title: t('message.logoutSuccess.title'), description: t('message.logoutSuccess.description'), color: 'green', timeout: 2000 })
}

const clickWithdrawal = () => {
  menuOpenTrigger.value = false
  navigateTo('/login/withdrawal')
}
</script>

<template>
  <div class="flex items-center gap-2">
    <AButton
      button-variant="ghost"
      :button-text="$t('button.logout')"
      @click="clickLogout"
    />
    <WMDivider
      orientation="vertical"
      size="xs"
      :ui="{ border: { base: 'border-[#E5E5E5]', vertical: 'h-4' } }"
    />
    <AButton
      button-variant="ghost"
      :button-text="$t('button.withdrawal')"
      @click="clickWithdrawal"
    />
  </div>
</template>
